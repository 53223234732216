/*
  # Reset
  # Outline
*/

.btn{
  border-radius: 0;
  transition: all 0.4s ease;
}
// # Reset
.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  //font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: none;
  }
}
.btn-mtorres{
  font-weight: 500;
	font-weight: 500;
  background-color: $azul;
  color: $white;
  font-size: 17px;
  padding: 1rem 3rem;
  transition: all 0.5s ease-out;
  &:hover{
    color: $white;
    background-color: #0a1d30;
    transition: all 0.5s ease-out;
  }
}
.btn-mtorres.light{
  font-weight: 500;
  background-color: #A2C2E9;
  font-size: 14px;
  &:hover{
    color: $white;
    background-color: #0a1d30;
    transition: all 0.5s ease-out;
  }
}
.btn-download{
  font-weight: 500;
  background-color: $naranja;
  color: $white;
  padding: 1.5rem 4rem;
  text-transform: uppercase;
  max-width: 380px;
  display: flex;
  &:hover{
    color: $white;
    background-color: #c64f00;
  }
}
.show-more{
  font-weight: 700;
  font-size: 15px;
  color: $azul;
  text-transform: uppercase;
  display: flex;
  align-items:center;
  .icon-arrow-left{
    fill: $azul;
    width: 27px;
    height: 27px;
    padding-left: 0.5rem;
  }
}
// # Outline
.no-outline {
  &:focus {
    outline: none;
  }
}

// # Anchors
.btn-anchor {
	border: 1px solid lighten($naranja, 10%);
	border-bottom: none;
	border-right: none;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 auto;
	padding: .75rem;
  z-index: 5;
  background-color: $naranja;
  color: $white;
  font-size: 1rem;
  font-weight: 600;
  &:hover{
    color: $white;
    background: darken($naranja,10%);
  }
	@include media-breakpoint-up(lg) {
		padding: 1.2rem 1.5rem;
	}
}
