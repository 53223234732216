// Contacto
.main-contacto{
	.masthead{
		margin-bottom: 3rem;
		color: $dark;
	}
	.asistencia{
		padding: 5rem 0;
		h2{
			color: #036;
			margin-bottom: 2rem;
		}
		a{
			color: #036;
		}
		.form-control {
		    padding: 1rem;
		}
		select.form-control:not([size]):not([multiple]) {
		    height: calc(3.5rem + 2px);
		}
	}
	.convocatorias{
		padding: 5rem 0;
		.home-h2{
			color: $dark;
		}
		.box {
		    width: 100%;
		    background-color: #fff;
		    text-align: left;
		    position: relative;
		    height: 100%;
		    box-shadow: 0px 2px 6px 0px rgba(204, 209, 217, .5);
		    border: 1px solid #E6E9ED;
		}
		.box-txt {
		    padding: 7%;
		    min-height: 140px;
		}
		.font-bold{
			font-weight: 600;
			color: $dark;
		}
		.show-more{
			font-weight: 600;
			color: $naranja;
			span{
				padding-right: 0.5rem;
			}
		}
	}
	.info{
		.modal-content{
			background-color: $azul!important;
			padding: 2rem 1rem 2rem;
			border-radius: 0;
		}
		.modal-header{
			padding: 0 1rem;
			border:0;
			span{
				color: $white;
			}
		}
		h3{
			font-size: 20px;
		}
		.icon{
			padding-right: 1rem;
		}
		.row{
			border: 1px solid #036;
			padding: 2rem 0;
		}
		span{
			font-size: 18px;
			text-transform: uppercase;
		}
		.sedes{
			h3, p{
				color: $white;
			}
		}
	}
	.commercials {
		background-size: cover;
		.container{
			background-color: rgba(255,255,255,0.9);
			margin: 4rem auto;
		}
		.tag{
			font-weight: 500;
    		font-size: 20px;
    		padding: 4rem 3rem 2rem;
    		transition: all .5s ease-out;
    		.link{
    			color: #000;
    			padding-bottom: 1rem;
    		}
    		.active{
    			border-bottom: 3px solid #000;
    		}
		}
		.commercial{
			text-align: center;
			padding-top: 1rem;
			padding-bottom: 1rem;
			img{
				border: 1px solid rgba(0,0,0,.1);
			}
		}
		.name{
			font-size: 20px;
			font-weight: 500;
			color: #000;
		}
		.phone, .email{
			strong{
				font-weight: 500!important;
				font-size: 15px;
				font-weight: 500;
				color: #000;
			}
		}
		.btn {
			&.active {
				background: $dark;
			}
		}
		.commercials-sector {
			&:not(.active) {
				display: none;
			}
		}
		.commercial {
			a{
				color: $azul;
				font-weight: 600;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.commercials {
			.commercial {
				padding: 1rem 2rem;
			}
		}
	}
}