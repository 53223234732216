// Corporativo
.main-corporativo{
	.categorias{
		.box-txt{
			padding: 2.5rem 0;
			h2, p{
				color: $dark;
			}
			@media only screen and (max-width: 991px) {
				padding: 1rem;
				h2{
					font-size: 30px;
				}
			}
		}
	}
	.masthead{
		margin: 0 auto 2rem;
		.row{
			align-items: center;
			.h1-cat{
				color: $dark;
			}
			.box-img{
				display: flex;
    			align-items: center;
    			img{
    				padding: 3rem;
    			}
				.bg-orange{
					position: absolute;
					right: 0;
					padding: 8rem 2rem;
				}
				@media only screen and (max-width: 767px) {
					img{
						padding: 0;
					}
					.bg-orange{
						display: none;
					}
				}
			}
			.destacada{
				font-size: 18px;
				font-weight: 700;
				p{
					margin: 0;
					color: $dark;
				}
			}
		}
	}
	.descripcion{
		padding: 4rem 2rem;
    	column-gap: 20px;
    	color: $dark;
    	@media only screen and (min-width: 992px) {
			column-count: 2;
		}
	}
	.valores{
		.home-h2{
			color: $dark;
		}
		.align-items-end{
			margin-bottom: 3rem;
		}
		.box-img{
			padding: 0;
		}
		.box-txt{
			padding: 4rem 3rem 2rem;
			.h3{
				font-size: 22px;
				font-weight: 700;
				color: $dark;
			}
		}
		.order:nth-child(3n), .order:nth-child(4n), .order:nth-child(7n), .order:nth-child(8n) {
			.row{
				flex-direction: row-reverse;
			}
		}
		@media only screen and (min-width: 992px) {
			.box-txt{
	    		min-height: 420px;
			}
		}
		@media only screen and (max-width: 991px) {
			.align-items-end{
				max-width: 600px;
				margin: 0 auto 2rem;
			}
		}
		@media only screen and (max-width: 1400px) {
			.box-txt{
				padding: 3rem 2rem 2rem;
				.h3{
					font-size: 21px;
				}
			}
		}
	}
	.cifras{
		.home-h2{
			color: $dark;
		}
		span{
			color: $naranja;
		}
		.col-12{
			margin-bottom: 4rem;
		}
		.box{
			padding: 3rem;
			height: 100%;
			box-shadow: 0 5px 10px rgba(0,0,0,0.05), 0 5px 10px rgba(0,0,0,0.05);
		}
		p{
			font-size: 16px;
			font-weight: 700;
			color: $dark;
		}
		.poppins{
			color: $naranja;
			font-size: 40px;
		}
	}
	.historia{
		.home-h2{
			color: $dark;
		}
	}
	&.america {
		h2.h1-cat{
				font-size: 3rem;
				margin-bottom: 2rem;
				color: #343a40;
			}
		.block-text-image{
			h3{
				font-weight: 500;
				color: #343a40;
			}
			h4{
				font-weight: 700;
				font-size: 20px;
				margin-top: 3rem;
				color: #036;
			}
			h5{
				font-size: 16px;
				font-weight: 700;
				margin: 2rem 0 1rem 0;
			}
		}
		.box-txt{
			h3{
			    font-size: 20px;
			    margin-bottom: 1rem;
			}
		}
		.commercials {
			background-size: cover;
			.container{
				background-color: rgba(255,255,255,0.9);
				margin: 4rem auto;
			}
			.tag{
				font-weight: 500;
				font-size: 20px;
				padding: 4rem 3rem 2rem;
				transition: all .5s ease-out;
				.link{
					color: #000;
					padding-bottom: 1rem;
				}
				.active{
					border-bottom: 3px solid #000;
				}
			}
			.commercial{
				text-align: center;
				padding: 1rem 2rem;
				img{
					border: 1px solid rgba(0,0,0,.1);
				}
			}
			.name{
				font-size: 20px;
				font-weight: 500;
				color: #000;
			}
			.phone, .email{
				strong{
					font-weight: 500!important;
					font-size: 15px;
					font-weight: 500;
					color: #000;
				}
			}
			.btn {
				&.active {
					background: $dark;
				}
			}
			.commercial {
				a{
					color: $azul;
					font-weight: 600;
				}
			}
		}
	}
}
.etica{
	.descripcion{
		ul{
			list-style: none!important;
			li{
				position: relative;
				padding-left: 1rem;
				&:before{
					position: absolute;
					content: " ";
					background-color: $naranja;
					width: 5px;
    				height: 5px;
    				left: 0;
   					top: 10px;
					border-radius: 50%;
				}
			}
		}
	}
	.tabs {
	  display: flex;
	  flex-wrap: wrap; // make sure it wraps
	  color: $gray;
	  h3{ 
	  	font-weight: 700;
	  	font-size: 24px;
	  	font-weight: 700;
	  	color: $dark;
	  	margin-top: 3rem;
	  	margin-bottom: 1rem;
	  }
	  a:not(.btn-download){
	  	color: $naranja;
	  	text-decoration: underline;
	  	text-transform: uppercase;
	  	font-weight: 700;
	  }
	}
	.tabs label {
	  order: 1; // Put the labels first
	  display: block;
	  padding: 1rem 2.5rem;
	  margin-right: 0.2rem;
	  cursor: pointer;
	  color: $dark;
	  font-size: 20px;
	  font-weight: 500;
	  border-bottom: 4px solid $naranja;
	  opacity: 0.5;
	}
	.tabs .tab {
	  order: 99; // Put the tabs last
	  flex-grow: 1;
	  width: 100%;
	  display: none;
	  padding: 1rem;
	  background: #fff;
	}
	.tabs input[type="radio"] {
	  display: none;
	}
	.tabs input[type="radio"]:checked + label {
	  border-bottom: 4px solid $naranja;
	  color: $dark;
	  font-weight: 700;
	  opacity: 1;
	}
	.tabs input[type="radio"]:checked + label + .tab {
	  display: block;
	}
	@media (max-width: 45em) {
		 .tabs .tab,
		 .tabs label {
		    order: initial;
		 }
	  	.tabs label {
	    	width: 100%;
	    	margin-right: 0;
	    	margin-top: 0.2rem;
	  	}
	}
}
.negocio, .rsc{
	.categorias, .subcategorias, .detalle{
		padding: 5rem 0;
		a{
			color: $gray;
			h2{
				font-weight: 700;
			}
		}
	}
	.subcategorias{
		h2{
			font-size: 22px;
			color: $dark;
		}
	}
	.detalle{
		h2{
			font-size: 22px;
			font-weight: 700;
			margin-top: 2rem;
		}
	}
}
.rsc{
	padding: 4rem 0;
}
.negocio{
	padding: 4rem 0 0;
	.categorias{
		margin-top: 4rem;
		.clanOTbold{
			color: $dark;
			margin: 2rem 0 1rem;
		}
	}
}
.acreditaciones{
	h2{
		color: $dark;
		text-transform: uppercase;
	}
	h3{
		color: $dark;
		font-size: 35px;
	}
	.descargables{
		padding: 5rem 0;
		.col-lg-3{
			padding: 2rem;
		}
		.box-shadow{
			padding: 2rem 1rem 1rem;
			h4{
				font-size: 17px;
				margin: 0;
			}
			.box-txt{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 1rem 0;
			}
			.download-btn{
				display: flex;
				align-items:center;
				justify-content: center;
				background-color: $dark;
				color: $white!important;
				border-radius: 50%;
				width: 27px;
				height: 27px;
				transform: rotate(90deg);
				font-size: 0.9rem;
				span{
					position: relative;
				}
			}
		}
		a{
			color: $dark;
			transition: all 0.4s ease;
			&:hover{
				h4{
					color: $naranja;
					transition: all 0.4s ease;
				}
				.download-btn{
					background-color: $naranja;
				}
			}
		}
	}
	.col-md-10 .row:nth-child(2n){
		flex-direction: row-reverse;
	}
}
.rsc{
	.bloques{
		h5{
			color: $azul;
			font-size: 15px;
			font-weight: 700;
			text-transform: uppercase;
		}
		.icon{
			width: 100px;
		}
	}
}

//Mapa de localizaciones
#locations{
	height: 600px;
}
.gm-style-iw.gm-style-iw-c{
	border-radius: 0!important;
	padding: 1rem!important;
}
.info__map{
	font-weight: 400;
	text-align: left;
	h2{
		font-weight: 700;
		font-size: 18px;
		font-weight: 600;
		color: #000;
	}
	h3{
		font-weight: 700;
		font-size: 15px;
	}
	p{
		font-size: 14px;
		margin-bottom: .5rem;
	}
	strong {
		font-weight: 500;
	}
	&.in__map{
		max-width: 300px;
		img{
			max-width: 100%;
		}
	}
}
