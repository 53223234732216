// Footer
.footer-long{
  padding: 2rem 0;
  .container{
    max-width: 1600px;
  }
  a{
    color: $white;
    &:hover{
      color: $white;
      opacity: 0.7;
    }
  }
  .text-uppercase{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  ul{
    list-style: none;
    padding-left: 0;
    li{
      font-size: 14px;
      margin-bottom: 0.8rem;
    }
  }
  .newsletter{
    padding: 2rem 0;
    h3{
      font-size: 18px;
    }
    .form-group.input{
      padding: 0;
      input{
        padding: .9rem .75rem;
      }
    }
  }
  .bottom{
    border-top: 1px solid $white;
    padding-top: 1.5rem;
    a{
      text-transform: initial;
      padding: 0.5rem;
    }
    .follow{
      text-transform: initial;
      padding-right: 1.5rem;
    }
    i{
      font-size: 18px;
    }
    @media (max-width: 991px) {
      ul, .justify-content-end{
        justify-content: center!important;
        flex-wrap: wrap;
      }
    }
  }
}