// servicios
.main-servicios{
	.masthead{
		color: $dark;
		max-width: 1400px;
		margin: 0 auto;
		.h1-cat{
			color: $dark;
		}
		.intro{
			color: $gray;
		}
		.row{
			align-items: center;
			.box-img{
				display: flex;
    			align-items: center;
    			img{
    				padding: 3rem 3rem 3rem 0;
    			}
				@media only screen and (max-width: 1000px) {
					img{
						padding: 0;
					}
				}
			}
		}
		@media only screen and (min-width: 992px) {
			.text-left{
				padding-right: 9rem;
			}
		}
	}
	/* .categorias{
		max-width: 1400px;
		margin: 0 auto;
		.h2{
		 	font-weight: 600;
		 	color: $dark;
		}
		.zoom{
			img{
				width: 100%;
			}
		}
		.mb-6{
			margin-bottom: 6rem; 
		}
		.mb-10{
			margin-bottom: 10rem; 
		}
	} */
	.categorias{
		max-width: 1200px;
		margin: 0 auto;
		h2{
			color: $dark;
		}
		a{
			.h2{
				font-size: 22px;
				color: $dark;
			}
			p{
				color: $gray;
			}
		}
		.mb-6{
			margin-bottom: 6rem;
		}
		.col-12, .col-lg-6 {
			padding: 0 3rem;
		}
	}
}
.main-servicios.detalle{
	.masthead{
		max-width: 1400px;
	}
	.descripcion{
		@media only screen and (min-width: 992px) {
			column-count: 2;
			p{
				padding: 0 2rem;
			}
		}
		ul{
			padding-left: 4rem;
			font-size: 14px;
			li{
				font-family: 'Poppins', sans-serif;
			}
		}
	}
	.casos, .interes{
		.home-h2{
			font-size: 45px;
			margin-top: 3rem;
		}
		a{
			color: $dark;
			transition: all 0.4s ease;
			&:hover{
				color: $naranja;
			}
		}
	}
	.casos{
		.casos-carousel {
		    text-align: left;
		    width: 100%;
		    margin-left: auto;
		    margin-right: auto;
		    margin-top: 30px;
		    padding-right: 5%;
		    padding-left: 5%;
		    margin-bottom: 90px;
		}
		.box {
		    width: 100%;
		    text-align: left;
		    position: relative;
		    height: 100%;
		}
		.casos {
		    margin-top: 30px;
		    margin-bottom: 60px;
		}
		.slide {
		    width: 33%;
		    padding-right: 2%;
		    padding-left: 2%;
		    margin-bottom: 30px;
		    float: left;
		    @media only screen and (max-width: 991px) {
				width: 50%;
			}
			@media only screen and (max-width: 768px) {
				width: 100%;
			}
		}
		.flickity-prev-next-button.previous {
		  left: -30px;
		  	@media only screen and (max-width: 1400px) {
				left: 0!important;
			}
		}
		.flickity-prev-next-button.next {
		  right: -30px;
		  	@media only screen and (max-width: 1400px) {
				right: 0!important;
			}
		}
		.flickity-prev-next-button .arrow {
		  fill: #000;
		}
		.flickity-prev-next-button {
		  background-color: #fff;
		  width: 38px;
		  height: 38px;
		}
		.font-bold{
			font-weight: 600;
		}
		.btn-download{
			width: 180px;
			padding: 1rem;
			span{
				padding-right: 0.8rem;
			}
		}
	}
	.interes{
		padding: 3rem 0;
		h2, h3, p{
			color: $white;
		}
		h3{
			font-size: 20px;
		}
		.col-lg-3{
			padding: 2rem;
		}
	}
}