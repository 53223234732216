.block {
  //Generaless
  &.mb-6, &.my-6{
    margin-bottom: 6rem;
  }
  &.mt-6, &.my-6{
    margin-top: 6rem;
  }
  //Texto + imagen (lateral o superior/inferior)
  &.block-text-image{
    .intro{
      color: rgba(0,0,0,.6);
      font-weight: 500;
      font-size: 20px;
    }
    .img-footer
    {
      .bg-orange {
        padding: 2rem;
        color: #fff;
        margin-bottom: 3rem;
        *:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  //Galería
  &.block-gallery{
    .home-h2{
      font-size: 35px;
      color: #000;
      margin-bottom: 3rem;
    }
    .carousel-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28%;
      min-height: 500px;
      margin-right: 10px;
      background-color: #dedede;
      //opacity: 0.5;
      .box{
        padding: 3rem;
      }
      .box-img{
        text-align: center;
        overflow: hidden;
        margin-bottom: 2rem;
      }
      img{
        mix-blend-mode: multiply;
      }
      h3{
        font-size: 18px;
        font-weight: 600;
      }
      @media only screen and (max-width: 991px) {
        width: 50%;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
    .carousel-cell.is-selected {
      opacity: 1;
    }
    .carousel.is-fullscreen {
      z-index: $zindex-modal;
      .carousel-cell {
        width: 100%;
        height: 100%;
        text-align: center;
        margin-top: 3rem;
      }
    }
    .carousel-cell-image {
      display: block;
      width: 100%;
      max-height: 100%;
    }
    .carousel.is-fullscreen .carousel-cell-image {
      max-width: 100%;
      width: 100%;
    }
    .flickity-prev-next-button .arrow {
      fill: #000;
    }
    .flickity-prev-next-button {
      background-color: transparent!important;
    }
  }
  //Baner
  &.block-banner{
    padding: 5rem 0;
    background-position: center;
    background-size: cover;
    .btn-mtorres{
      background-color: $naranja;
      font-weight: 600;
    }
  }
  //Descargables
  &.block-download{
    .box-img{
      padding: 0;
      img{
        width: 100%;
      }
    }
    .box-txt{
      padding: 4rem;
      color: $white;
      font-size: 30px;
    }
    h4{
      font-size: 40px;
      font-weight: 700;
    }
    .btn-download{
      font-size: 20px;
      padding: 1rem 0 0;
      text-decoration: underline;
      &:hover{
        background-color: transparent;
      }
    }
  }
  //Bloque testimonio/frase destacada
  &.block-testimonial{
    .slide{
      display: flex;
      justify-content: center;
    }
    blockquote{
      margin: 0;
      padding: 6rem;
      color: white;
      position: relative;
      transition: background-color 0.6s linear;
      font-size: 40px;
      @media (max-width:767px){
        font-size: 30px;
        padding: 6rem 2rem;
      }
    }
    .quotes{
      position: absolute;
      color: rgba(255,255,255,0.5);
      font-size: 7em;
    }
    .leftq{
      top: -40px;
      left: -10px;
    }
    .rightq{
      bottom: -40px;
      right: 5px;
    }
    li{
      font-size: 17px;
    }
  }
  table{
    font-size: 16px;
    margin-top: 1rem;
    th{
			font-weight: 700;
      font-size: 1.3em;
      background: #455359;
      padding: 1rem;
      color: #FFF;
      border-bottom: 1px solid #FFF;
    }
    td{
      background: #f0f0f0;
      padding: 0.8rem;
      color: #000;
      border-bottom: 1px solid #FFF;

    }
    p{
      margin-bottom: 0;
    }
  }
}

//Especificos de feder
.feder-blocks {
  .block {
    .h1-cat {
      font-size: 20px;
    }
  }
}
// Bloque a dos columnas
.block-text-columns{
  font-size: 16px;
  h3{
    font-weight: 700;
    color: #000;
  }
}