// materiales
.main-materiales{
	.masthead{
		margin: 3rem auto 0;
		.container{
			max-width: 1200px;
		}
		.h1-cat{
			color: $dark;
			padding: 0;
		}
		.row{
			align-items: center;
			.box-img{
				display: flex;
    			align-items: center;
    			img{
    				padding: 3rem 3rem 3rem 0;
    			}
				@media only screen and (max-width: 767px) {
					img{
						padding: 0;
					}
				}
			}
		}
	}
	.descripcion{
		color: $dark;
		@media only screen and (min-width: 992px) {
			column-count: 2;
			p{
				padding: 0 2rem;
			}
		}
		ul{
			padding-left: 4rem;
			li{
				font-family: 'Poppins', sans-serif;
			}
		}
	}
	.categorias{
		max-width: 1200px;
		margin: 0 auto;
		a{
			.h2{
			 	font-size: 22px;
			 	color: $dark;
			}
			p{
				color: $gray;
			}
		}
		.mb-6{
			margin-bottom: 6rem; 
		}
		.col-lg-6{
			padding: 0 3rem;
			.col-12{
				padding: 0;
			}
		}
	}
	.casos{
		.home-h2{
			font-size: 45px;
			margin-top: 3rem;
			@media (max-width:991px){
			      font-size: 40px;
			  }
			  @media (max-width:600px){
			      font-size: 30px;
			  }
		}
		a{
			color: $dark;
			transition: all 0.4s ease;
			&:hover{
				color: $naranja;
			}
		}
	}
	.casos{
		overflow: hidden;
		margin: 6rem 0 2rem;
		padding: 3rem 0;
		.home-h2{
			color: $white;
			margin-top: 1rem;
		}
		.casos-carousel {
		    text-align: left;
		    width: 100%;
		    margin-left: auto;
		    margin-right: auto;
		    margin-top: 0;
		    padding-right: 5%;
		    padding-left: 5%;
		    margin-bottom: 0;
		}
		.box {
		    width: 100%;
		    text-align: left;
		    position: relative;
		    height: 100%;
		}
		.casos {
		    margin-top: 0;
		    margin-bottom: 0;
		}
		h3{
			color: $white;
			font-size: 18px;
		}
		.slide {
		    width: 33%;
		    padding-right: 2%;
		    padding-left: 2%;
		    margin-bottom: 0;
		    float: left;
		    @media only screen and (max-width: 991px) {
				width: 50%;
			}
			@media only screen and (max-width: 768px) {
				width: 100%;
			}
		}
		.flickity-prev-next-button.previous {
			left: 0!important;
		}
		.flickity-prev-next-button.next {
			right: 0!important;
		}
		.flickity-prev-next-button .arrow {
		  fill: #fff;
		}
		.flickity-prev-next-button {
		  background-color: transparent!important;
		  width: 38px;
		  height: 38px;
		}
		.font-bold{
			font-weight: 600;
		}
		.btn-download{
			width: 180px;
			padding: 1rem;
			span{
				padding-right: 0.8rem;
			}
		}
	}
}
.main-materiales.detalle{
	.masthead{
		.text-center{
			padding: 3rem 1rem 0;
		}
		.justify-content-end{
			width: 100%;
		}
		.col-md-12.text-center{
			padding: 0;
		}
		.bg-orange{
			padding: 2rem;
			color: $white;
			margin-bottom: 3rem;
		}
	}
	.descripcion{
		padding: 3rem 1rem;
	}
	.caracteristicas{
		font-size: 18px;
		border-bottom: 2px solid #dedede;
		padding-bottom: 6rem;
		h3{
			font-size: 35px;
			font-weight: 800;
			margin-bottom: 4rem;
			text-align: center;
		}
		h4{
			font-size: 20px;
			margin-bottom: 1rem;
		}
		img{
	 		border: 1px solid #dedede;
	 	}
	 	span,strong{
	 		color: $azul;
	 		font-weight: 700;
	 	}
	 	.col-md-8{
	 		padding-top: 1rem;
	 	}
	 	.box{
	 		background-color: #e3e8ed;
    		padding: 3rem;
    		margin-bottom: 3rem;
	 	}
	}
	.video{
		background-color: #9d9d9d;
		padding: 5rem 0;
		.container{
			max-width: 1200px;
		}
		iframe{
			border: 0;
		}
		.justify-content-start{
			align-items: center;
		}
		.box-txt{
			color: $white;
		}
	}
}