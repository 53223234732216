.validation-form{
  .invalid-feedback{
    display: block;
  }
}
input, textarea, select{
	border-radius: 0!important;
}
.form-bottom{
	padding: 5rem 1rem;
	position: relative;
	h2{
		font-size: 45px;
		font-weight: 700;
	}
	.form{
		text-align: center;
		color: $white;
	}
	.form-control{
		background-color: #f60;
		color: $white;
		border-color: $white;
		padding: 1rem;
		&::placeholder{
			color:$white;
		}
	}
	select.form-control:not([size]):not([multiple]) {
	    height: calc(3.5rem + 2px);
	}
	.input, p{
		color: $white;
		&.text-success {
			padding: 1rem;
			background-color: $success;
		}
	}
	a{
		color: $white;
		font-weight: 600;
	}
	.btn-mtorres{
		background-color: $white;
		color: $naranja;
		font-size: 17px;
		padding: 0.8rem 3rem;
		text-transform: uppercase;
	}
	.close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		font-size: 3em;
		display: none;
		&:hover{
			opacity: .5;
		}
	}
	&.modal-type{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		max-height: 100%;
		overflow: auto;
		z-index: $zindex-tooltip;
		transition: all 1000ms;
		.close {
			display: block;
		}
	}
}
/* .sedes{
	border-top: 1px solid $white;
	padding: 3rem 1rem;
	color: $white;
	h3{
		font-size: 20px;
	}
	.icon{
		padding-right: 1rem;
	}
} */

.input{
	.form-check-label {
		padding-left: 1.25rem
	}
}