
//Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
.poppins { font-family: 'Poppins', sans-serif }

// ClanOT
@font-face {
	font-family: 'Conv_ClanOT';
	src: url('../theme/fonts/clanot/ClanOT-Book.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-Book.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-Book.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-Book.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT';
	src: url('../theme/fonts/clanot/ClanOT-BookItalic.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-BookItalic.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-BookItalic.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-BookItalic.svg') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Conv_ClanOT';
	src: url('../theme/fonts/clanot/ClanOT-Medium.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-Medium.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-Medium.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-Medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT';
	src: url('../theme/fonts/clanot/ClanOT-MediumItalic.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-MediumItalic.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-MediumItalic.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-MediumItalic.svg') format('svg');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Conv_ClanOT';
	src: url('../theme/fonts/clanot/ClanOT-Bold.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-Bold.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-Bold.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT';
	src: url('../theme/fonts/clanot/ClanOT-BoldItalic.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-BoldItalic.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-BoldItalic.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-BoldItalic.svg') format('svg');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Conv_ClanOT';
	src: url('../theme/fonts/clanot/ClanOT-ExtdBold.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-ExtdBold.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-ExtdBold.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-ExtdBold.svg') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT';
	src: url('../theme/fonts/clanot/ClanOT-ExtdBoldItalic.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-ExtdBoldItalic.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-ExtdBoldItalic.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-ExtdBoldItalic.svg') format('svg');
	font-weight: 900;
	font-style: italic;
}

// 
@font-face {
	font-family: 'Conv_ClanOT-News';
	src: url('../theme/fonts/clanot/ClanOT-News.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-News.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-News.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-News.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

.clanOTnews{ font-family: 'Conv_ClanOT-News' }

/* Comprobar si se usa la italic

@font-face {
	font-family: 'Conv_ClanOT-NewsItalic';
	src: url('../theme/fonts/clanot/ClanOT-NewsItalic.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-NewsItalic.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-NewsItalic.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-NewsItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
*/

/* Sin uso

@font-face {
	font-family: 'Conv_ClanOT-ExtdBook';
	src: url('../theme/fonts/clanot/ClanOT-ExtdBook.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-ExtdBook.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-ExtdBook.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-ExtdBook.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT-ExtdBookItalic';
	src: url('../theme/fonts/clanot/ClanOT-ExtdBookItalic.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-ExtdBookItalic.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-ExtdBookItalic.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-ExtdBookItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT-ExtdMedium';
	src: url('../theme/fonts/clanot/ClanOT-ExtdMedium.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-ExtdMedium.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-ExtdMedium.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-ExtdMedium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT-ExtdMediumItalic';
	src: url('../theme/fonts/clanot/ClanOT-ExtdMediumItalic.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-ExtdMediumItalic.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-ExtdMediumItalic.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-ExtdMediumItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT-ExtdNews';
	src: url('../theme/fonts/clanot/ClanOT-ExtdNews.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-ExtdNews.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-ExtdNews.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-ExtdNews.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_ClanOT-ExtdNewsItalic';
	src: url('../theme/fonts/clanot/ClanOT-ExtdNewsItalic.eot');
	src: local('☺'), url('../theme/fonts/clanot/ClanOT-ExtdNewsItalic.woff') format('woff'), url('../theme/fonts/clanot/ClanOT-ExtdNewsItalic.ttf') format('truetype'), url('../theme/fonts/clanot/ClanOT-ExtdNewsItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
*/