.cms {
	ul {
		list-style: disc;
	}
  ul, ol {
    text-align: left;
    margin-bottom: 1rem;
    > li {
      &:last-child {
        margin-bottom: 0;
      }
      ul, ol {
        margin-top: .5rem;
        margin-bottom: 0;
      }
    }  
    > li {
      margin-bottom: .5rem
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
	p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.intro {
	&.cms {
		ul {
			list-style: disc
		}
	}
}