// # Box
.box{
  border: 0;
  padding: 1rem 2rem;
  h3{
    font-weight: 400;
    color: #212121;
  }
  p{
    font-size: 17px;
    color: $gray;
  }
}
.box-img{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.box-bg{
  position: relative;
  padding: 3rem 2rem 6rem;
  .home-h2, .lead, .btn-mtorres {
    position: relative;
    color: $white;
    z-index: 2;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
  }
}
.box-shadow{
  box-shadow: 0 14px 28px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.10);
}
.box-1{
  .box-img{
    display: flex;
    align-items:center;
    justify-content: center;
    height: 230px;
  }
  h3{
    font-size: 23px;
    margin: 2rem 0 1rem;
  }
}
.box-2{
  .box-img{
    display: flex;
    align-items:center;
    justify-content: center;
  }
  h3{
    font-size: 24px;
    margin: 2rem 0 1rem;
  }
	@include media-breakpoint-up(md) {
		.box-img{
			height: 350px;
		}
	}
}
.box-3{
  padding: 2rem;
  a{
    color: $gray;
    height: 100%;
  }
  h3{
    font-size: 20px;
    font-weight: 400;
  }
  .box-img{
      display: flex;
      align-items:center;
      justify-content: center;
      height: 200px;
      overflow: hidden;
    }
  .destacado{
    h3{
      font-size: 24px;
      color: $dark;
      font-weight: 600;
    }
    .box-img{
      height: 480px;
      overflow: hidden;
      @media (max-width: 1300px){
        height: 300px;
      }
    }
  }
  .box-3-cat{
    color: $azul;
    font-weight: 700;
    margin-bottom: 0.85rem;
  }
  .box-txt{
    padding: 1.5rem;
  }
}
/* .block-y-5 {
  &.block-y-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
} */