// SLIDE
.home-carousel {
  .carousel-cell {
    width: 100%;
  }
  .carousel-box{
    width: 100%;
    background-color: $naranja;
  }
  .carousel-box-content{
    max-width: 1100px;
    margin: 0 auto;
    padding: 1rem;
    .carousel-btn {
			border: 1px solid #fff;
      font-weight: 500;
      font-weight: 700;
      color: $white;
			padding: .625rem 1rem;
    }
    h2, p, li{
      color: $white;
    }
    @media (min-width:767px){
      h2{
        font-size: 50px;
      }
      p{
        font-size: 17px;
      }
      a{
        font-size: 18px;
      }
    }
  }
  .flickity-prev-next-button{
    top: auto;
    bottom: 6%!important;
    @media (max-width:1300px){
      bottom: 50%!important;
    }
  }
  .flickity-prev-next-button.next{
      right: 50px;
  }
  .flickity-prev-next-button.previous{
      left: 50px;
  }
}
.home-h2{
  @media (min-width:767px){
    font-size: 45px;
  }
}
.section-intro{
  color: #666;
}
.home-noticias{
  @media (min-width:893px){
    img{
      width: 100%;
      height: auto;
    }
  }
}
.list-home-soluciones{
  .box-img{
    img{
      width: 100%;
      height: auto;
    }
  }
}

.slide-clients {
	max-width: (250 / 16) * 1rem;
}