.section-tabs{

  // Tabs
  .gc {
    box-sizing: border-box;
    display: inline-block;
    margin-right: -.25em;
    min-height: 1px;
    padding-left: 40px;
    vertical-align: top;
  }
  .tabs-container{
    margin-top: 4rem;
  }
  .tabs div {
    color: $dark;
    padding: 15px 20px 15px 40px;
    margin-bottom: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    font-weight: 700;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .tabs div:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .tabs div span.light {
    background-color: $naranja;
    height: 10px;
    width: 10px;
    position: absolute;
    top: 24px;
    left: 15px;
    border-radius: 100%;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .tabs div.active span.light {
    left: 0;
    height: 100%;
    width: 3px;
    top: 0;
    border-radius: 0;
  }
  .tabs div.active {
    position: relative!important;
    height: auto;
    padding: 15px 20px 15px 20px;
  }
  .left{
    width: 100%;
  }
  .right{
    width: 100%;
    .txt {
      position: relative;
      list-style: none;
      margin: 0;
      padding: 0;
      transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .box-txt {
      opacity: 0;
      transform: translateX(50px);
      position: absolute;
      list-style: none;
      transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .box-txt.active {
      position: relative;
      transition-delay: .3s;
      z-index: 2;
      opacity: 1;
      transform: translateX(0px);
      .col-md-6{
        padding: 1rem 2rem;
        img{
          border: 1px solid #dedede;
        }
        .pie-foto{
          font-size: 14px;
          font-weight: 600;
          margin-top: 1rem;
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .left{
      padding: 0;
    }
  }
  @media only screen and (min-width: 600px) {
    .left {
      width: 33.33333%;
    }
    .right {
      width: 66.66666%;
    }
  }
  @media only screen and (max-width: 539px) {
    .right{
      padding: 0;
      position: relative;
      overflow: hidden;
    }
  }
}