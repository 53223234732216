.main-programas {
  &.detalle {
    h1 {
      color: $dark;
    }
    .equipamientos {
      h2 {
        color: $dark;
      }
      a {
        color: $gray;
        &:hover {
          color: $azul;
        }
      }
    }
  }
}