.detalle{
	.carousel__slide {
		  width: 100%;
		}
		.flickity-enabled {
		  position: relative;
		}
		.flickity-slider {
		  width: 100%;
		}
		.flickity-page-dots {
			display: none;
		}
		.carousel-nav {
		  margin: 0 auto 32px auto;
		  text-align: center;
		  nav{
		  	border-bottom: 2px solid $gray;
    		padding-bottom: 1rem;
		  }
		  &__button {
		    background-color: transparent;
		    -webkit-appearance: none;
		    border: 0;
		    cursor: pointer; 
		  }
		  &__button--is-active {
		    color: $naranja;
		    font-weight: 700;
		  }
		}
		.historia-carousel {
		  position: relative;
		  width: 100%;
		  height: 100%;
		  display: flex;
		}
		.carousel-nav__button {
		  font-family: inherit;
		  font-weight: 700;
		  text-transform: uppercase;
		  letter-spacing: 0.05em;
		  padding: 4px 12px;
		}
		.carousel__slide {
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  background-color: #fff;
		  .row{
		  	max-width: 1200px;
		  }
		}
		.info{
			margin: 3rem 0;
		}
		.year{
			color: $naranja;
			font-size: 40px;
			font-weight: 700;
		}
		.carousel-btn {
		  position: absolute;
		  top: 0%;
		  bottom: 0;
		  margin: auto;
		  width: 50px;
		  height: 50px;
		  border-radius: 50%;
		  background-color: #fff;
		  border: none;
		  -webkit-appearance: none;
		  font-size: 2rem;
		  padding-bottom: 0.5rem;
		  box-shadow: 0px 5px 35px 0px rgba(0,0,0,0.15);
		  cursor: pointer;
		  transition: all 0.4s ease;
		  &--prev {
		    left: 24px;
		  }
		  &--next {
		    right: 24px;
		  }
		  &[disabled] {
		      color: #aaa;
		      box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.05);
		      cursor: default;
		  }
		}
	}
.interes-carousel-container{
	.home-h2{
		font-size: 45px;
		margin-top: 3rem;
		@media (max-width:991px){
			font-size: 40px;
		}
		@media (max-width:600px){
			font-size: 30px;
		}
	}
	a{
		transition: all 0.4s ease;
		&:hover{
			color: $naranja !important;
			h3{
				color: $naranja !important;
			}
		}
	}
	h3{
		font-size: 20px;
		margin: 2rem;
	}
	.col-md-3{
		padding: 2rem;
	}
	.carousel-cell{
		width: 25%;
		padding: 1rem;
		@media only screen and (max-width: 991px) {
			width: 50%;
		}
		@media only screen and (max-width: 768px) {
			width: 100%;
		}
	}
	.flickity-prev-next-button
	{
		top: 40%;
		&.previous
		{
			left: 0;
		}
		&.next{
			right: 0;
		}
	}
}