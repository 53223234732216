// Noticias
.main-comunicacion{
	.masthead{
		.container{
			max-width: 1600px;
		}
	}
	.h1-cat{
		color: $dark;
		padding: 0;
	}
	.card{
		border-radius: 0;
		.bg-img{
			top: -50%;
		}
	}
	.categorias{
		a{
			font-size: 14px;
			text-transform: uppercase;
		}
	}
	.destacadas{
		padding: 3rem;
		.box-img {
		    max-height: 400px;
		    height: 100%;
		    overflow: hidden;
		}
	}
	.comunicacion-h2{
		font-size: 33px;
	    font-weight: 700;
	    color: $azul;
	    padding-left: 2rem;
	}
	.lead{
		font-size: 1rem;
		padding-left: 2rem;
	}
	.box-txt{
		small{
			color: $azul;
			font-size: 12px;
		}
		h3{
			margin-top: 1rem;
		}
	}
	.box-img{
	    img{
	      @media (min-width:1612px){
	        width: 100%;
	        height: max-content;
	      }
	    }
	  }
	.btn-secondary{
		background-color: $azul;
		border: 0;
	}
	.page-link{
		border: 0;
		color: $azul;
	}
	.page-item.active .page-link{
		background-color: $azul;
		border: 0;
	}
	@media (max-width: 1800px) {
		li.box-3{
			max-width: 33.3%;
		}
	}
	@media (max-width: 1450px) {
		li.box-3{
			max-width: 50%;
		}
	}
	@media (max-width: 1199px) {
		.row{
			justify-content: center;
		}
		.col-lg-3{
			max-width: 100%!important;
		}
		.col-lg-9{
			max-width: 100%!important;
		}
	}
	@media (min-width: 992px){
		.col-lg-3{
			flex: 0 0 50%;
		}
	}
}
.noticias{
	.noticias-listado{
		h3{
			color: $dark;
			font-size: 24px;
    		font-weight: 700;
    		margin-bottom: 1.5rem;
		}
	}
	.destacadas{
		.box-img {
		    min-height: 400px;
		    @media (max-width: 600px){
		    	max-height: 300px;
		    	min-height: 300px;
		    }
		}
	}
}
.noticias.detalle{
	.poppins{
		font-weight: 700;
		color: $azul;
	}
	.date{
		opacity: 0.6;
		span{
			padding: 0 0.3rem;
		}
	}
	.go-back{
		font-size: 16px;
		.icon-arrow-left{
			transform: rotate(180deg);
		}
	}
}
.eventos{
	.destacadas{
		.box-img {
		    height: 200px;
		    overflow: hidden;
		}
	}
}
.prensa{
	.destacadas{
		.box-img {
		    height: 300px;
		    overflow: hidden;
		}
		a{
			color: $dark;
			transition: all 0.4s ease;
			&:hover{
				h4{
					color: $naranja;
					transition: all 0.4s ease;
				}
				.download-btn{
					background-color: $naranja;
				}
			}
		}
		.type{
			display: flex;
			flex-wrap: wrap;
			padding: 1.5rem 1.5rem 0;
			small{
				width: 100%;
			}
			.date{
				opacity: 0.6;
			}
		}
		.box-3{
			max-width: 100%;
		}
		.box-txt{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.5rem 1.5rem 1.5rem;
			h4{
				padding-right: 1rem;
			}
		}
		.download-btn{
			display: flex;
			align-items:center;
			justify-content: center;
			background-color: $dark;
			color: $white!important;
			border-radius: 50%;
			width: 27px;
			height: 27px;
			transform: rotate(90deg);
		}	
	}
	@media (max-width: 1500px){
		.col-lg-4{
			flex: 0 0 50%;
		}
	}
	@media (max-width: 767px){
		.destacadas{
			padding: 3rem 1rem;
		}
		.col-lg-4{
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}
.identidad{
	.descargables{
		.col-lg-3{
			padding: 2rem;
		}
		.box-shadow{
			height: 100%;
			padding: 2rem 1rem 1rem;
			h4{
				font-size: 15px;
				padding-right: 1rem;
				margin: 0;
			}
			.box-img{
				height: 200px;
				overflow: hidden;
			}
			.box-txt{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 1rem 0;
			}
			.download-btn{
				display: flex;
				align-items:center;
				justify-content: center;
				background-color: $dark;
				color: $white!important;
				border-radius: 50%;
				width: 27px;
				height: 27px;
				transform: rotate(90deg);
				span{
					position: relative;
				}
			}
		}
		a{
			color: $dark;
			transition: all 0.4s ease;
			&:hover{
				h4{
					color: $naranja;
					transition: all 0.4s ease;
				}
				.download-btn{
					background-color: $naranja;
				}
			}
		}
		.type{
			color: $azul;
		}
		@media (max-width: 1500px){
			.col-lg-3{
				flex: 0 0 50%;
			}
		}
		@media (max-width: 767px){
			.col-lg-3{
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
}
.videos{
	.descripcion{
		h3{
			color: $dark;
			font-size: 25px;
		}
	}
	.carousel{
		border: 1px solid #dedede;
	}
	.carousel-cell{
		display: flex;
    	align-items: center;
    	justify-content: center;
		width: 100%;
		height: 100%;
		min-height: 450px;
		background-color: $white;
		.box{
			width: 100%;
			height: 600px;
    		padding: 0;
		}
		.box-img{
			min-height: 450px;
			max-height: 600px;
			width: auto;
			img{
				width: 100%;
				height: auto;
			}
			iframe{
				width: 100%;
			}
		}
	}
	.flickity-page-dots{
		bottom: -40px;
	}
}
.contacto{
	.mb-5{
		margin-bottom: 5rem;
	}
	.destacadas{
		.box-shadow{
			display: flex;
    		padding: 2rem;
    		.box-img {
			    height: 200px;
			    overflow: hidden;
			}
			.box-txt{
				padding: 0 2rem;
				font-weight: 600;
				span{
					font-weight: 700;
				}
				.name{
					color: $azul;
					margin-top: 1rem;
				}
				a{
					color: $dark;
				}
			}
		}
		@media (min-width: 1500px) {
			.col-12{
				max-width: 50%!important;
			}
		}
	}
}