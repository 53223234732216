// equipamientos
.main-equipamientos{
	.mb-6{
		margin-bottom: 6rem; 
	}
	.mb-10{
		margin-bottom: 10rem; 
	}
	.masthead{
		max-width: 1200px;
		margin: 0 auto;
		.row{
			align-items: center;
			.box-img{
				display: flex;
    			align-items: center;
    			img{
    				padding: 3rem 3rem 3rem 0;
    			}
				@media only screen and (max-width: 767px) {
					img{
						padding: 0;
					}
				}
			}
		}
	}
	.categorias{
		max-width: 1200px;
		margin: 5rem auto;
		.col-lg-6{
			padding: 0 3rem;
		}
		.col-lg-9{
			@media only screen and (min-width: 902px) {
				.box-img{
					padding-left: 3rem;
				}	
			}
			@media only screen and (max-width: 900px) {
				.box-img{
					padding-left: 0!important;
					img{
						width: 100%;
					}
				}
			}
		}
		.link-categorias
		{
			.texto {
				color: $gray;
			}
		}
	}
	ul{
		.box-img{
			height: 250px;
			overflow: hidden;
		}
		.box-orange{
			height: 100%;
			background-color: $naranja;
			color: $white;
			img{
				width: 100%;
    			height: auto;
			}
		}
		h3{
			font-size: 18px;
			color: $white;
			margin: 1rem;
		}
		.col-lg-4{
			padding: 1rem;
		}
	}
}
.main-equipamientos.subcategoria{
	.mb-6{
		margin-bottom: 6rem; 
	}
	.h1-cat{
		margin-top: 4rem;
		margin-bottom: 3rem;
	}
	.masthead{
		margin-bottom: 6rem;
		.row{
			align-items: center;
			.box-img{
				display: flex;
    			align-items: center;
    			img{
    				padding: 3rem 3rem 3rem 0;
    			}
				@media only screen and (max-width: 767px) {
					img{
						padding: 0;
					}
				}
			}
		}
	}
	.categorias{
		max-width: 1200px;
		margin: 0 auto;
	}
	ul{
		.box-img{
			height: 180px;
			overflow: hidden;
		}
		.box-orange{
			height: 100%;
			background-color: $naranja;
		}
		h3{
			font-size: 20px;
			color: $white;
			margin: 0;
		}
		p{
			color: $white;
		}
		.col-lg-3{
			padding: 1rem;
		}
	}
	.types{
		.col-lg-6{
			padding: 2rem;
			.box-shadow{
				padding: 2rem;
				h3{
					color:$dark;
					line-height: 50px;
					span{
						font-size: 20px;
						border: 1px solid #dedede;
						border-radius: 50%;
						padding: 1.2rem .8rem .8rem;
						margin-right: 1rem;
					}
				}
			}
		}
		.blue{
			.box-shadow{
				background-color: $azul;
				color: $white;
				h3{
					color: $white;
				}
			}
		}
	}
}
.main-equipamientos.detalle{
	.masthead{
		max-width: 1400px;
	}
	.bloques{
		.h1-cat{
			color: $dark;
		}
		.poppins{
			color: rgba(0,0,0,.6);
			font-weight: 500;
		}
	}
	.soluciones{
		.row:nth-child(2n){
			flex-direction: row-reverse;
		}
		.box-img{
			padding: 0;
			img{
				width: 100%;
			}
		}
		.box-txt{
			padding: 4rem;
		}
		h4{
			font-weight: 600;
			font-size: 40px;
			color: $dark;
			position: relative;
			&:after {
			    @media (min-width:1200px){
			     	content: "";
					position: absolute;
				    display: block;
				    margin-top: -4rem;
				    background-color: $orange;
				    width: 2rem;
				    height: .2rem;
			    }
			}
		}
		.btn-download{
			font-size: 20px;
			padding: 1rem;
    		font-size: 15px;
    		width: 180px;
    		span {
			    padding-right: .8rem;
			}
		}
	}
}