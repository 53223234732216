// soluciones
.main-soluciones{
	.masthead{
		max-width: 1400px;
		margin: 0 auto;
		.intro{
			color: $gray;
		}
		.row{
			align-items: center;
			.box-img{
				display: flex;
    			align-items: center;
    			img{
    				padding: 3rem 3rem 3rem 0;
    			}
				@media only screen and (max-width: 767px) {
					img{
						padding: 0;
					}
				}
			}
		}
	}
	.categorias{
		max-width: 1200px;
		margin: 0 auto;
		a{
			.h2{
				font-size: 22px;
			}
			p{
				color: $gray;
			}
		}
		.mb-6{
			margin-bottom: 6rem;
		}
		.col-lg-6, .col-12 {
			padding: 0 2rem;
		}
	}
	.carousel-btn{
		top: 0;
	}
	.servicios{
		a{
			transition: all 0.4s ease;
			&:hover{
				color: $naranja;
			}
		}
		.home-h2{
			font-size: 50px;
		}
		.box-img{
			height: 280px;
			overflow: hidden;
			img{
				width: 100%;
    			height: auto;
			}
		}
		.box-shadow{
			height: 100%;
		}
		h3{
			font-size: 20px;
    		font-weight: 400;
			margin: 2rem;
		}
		.col-lg-4, .item{
			padding: 2rem;
		}
	}
	.equipamientos{
		.equipamientos-carousel {
		    text-align: left;
		    width: 100%;
		    margin-left: auto;
		    margin-right: auto;
		    margin-top: 30px;
		    padding-right: 5%;
		    padding-left: 5%;
		    margin-bottom: 90px;
		}
		.box {
		    width: 100%;
		    background-color: #fff;
		    text-align: left;
		    position: relative;
		    height: 100%;
		    box-shadow: 0px 2px 6px 0px rgba(204, 209, 217, .5);
		    padding: 2.5rem 2rem;
		    border: 1px solid #E6E9ED;
		}
		.equipamientos {
		    margin-top: 30px;
		    margin-bottom: 60px;
		}
		.slide {
		    width: 33%;
		    padding-right: 2%;
		    padding-left: 2%;
		    margin-bottom: 30px;
		    float: left;
		    @media only screen and (max-width: 991px) {
				width: 50%;
			}
			@media only screen and (max-width: 768px) {
				width: 100%;
			}
		}
		.box-txt {
		    padding: 7%;
		    min-height: 140px;
		}
		.flickity-prev-next-button.previous {
		  left: -30px;
		  	@media only screen and (max-width: 1400px) {
				left: 0!important;
			}
		}
		.flickity-prev-next-button.next {
		  right: -30px;
		  	@media only screen and (max-width: 1400px) {
				right: 0!important;
			}
		}
		.flickity-prev-next-button .arrow {
		  fill: #000;
		}
		.flickity-prev-next-button {
		  background-color: #fff;
		  width: 38px;
		  height: 38px;
		}
		.font-bold{
			font-weight: 600;
		}
		.btn-download{
			width: 180px;
			padding: 1rem;
			span{
				padding-right: 0.8rem;
			}
		}
	}
	.refsectores{
		/* .flickity-slider{
			transform: translateX(0)!important;
		} */
		.refsectores-carousel {
		    text-align: left;
		    width: 100%;
		    margin-left: auto;
		    margin-right: auto;
		    margin-top: 30px;
		    margin-bottom: 90px;
		}
		.box {
		    width: 100%;
		    text-align: left;
		    position: relative;
		    height: 100%;
		}
		.box-img{
			img{
				width: 100%;
    			height: auto;
			}
		}
		.refsectores {
		    margin-top: 30px;
		    margin-bottom: 60px;
		}
		.slide {
		    width: 50%;
		    padding-right: 2%;
		    padding-left: 2%;
		    margin-bottom: 30px;
		    float: left;
		    @media only screen and (max-width: 700px) {
				width: 100%;
			}
		}
		.box-txt {
		    padding: 7%;
		    min-height: 140px;
		}
		.flickity-prev-next-button.previous {
		  left: -30px;
		  	@media only screen and (max-width: 1400px) {
				left: 0!important;
			}
		}
		.flickity-prev-next-button.next {
		  right: -30px;
		  	@media only screen and (max-width: 1400px) {
				right: 0!important;
			}
		}
		.flickity-prev-next-button .arrow {
		  fill: #000;
		}
		.flickity-prev-next-button {
		  background-color: #fff;
		  width: 38px;
		  height: 38px;
		}
		.font-bold{
			font-weight: 600;
		}
		.btn-download{
			width: 180px;
			padding: 1rem;
			span{
				padding-right: 0.8rem;
			}
		}
	}
}
