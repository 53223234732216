// Header
.top{
  font-weight: 500;
  text-transform: uppercase;
  .container{
    max-width: 1600px;
  }
  .rrss{
    align-items: center;
    padding: 0.5rem 1rem;
    a{
      padding: 0.3rem;
    }
    i{
      font-size: 18px;
      color: $white;
    }
  }
  .navbar-nav{
    flex-direction: row;
    li{
      padding: 0.5rem 1rem;
      font-size: 13px;
      a{
        color: $white;
        font-size: 13px;
      }
    }
    .nav-corporative, .nav-comunication{
      ul{
        list-style: none;
        width: auto;
        z-index: $zindex-dropdown;
        padding: 1rem 0;
        display: none;
        li{
          white-space: nowrap;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .justify-content-end{ 
      align-items:center;
    }
  }
  @media only screen and (max-width: 767px) {
    .hidden-xs{
      display: none;
    }
  }
}
.bottom{
  font-weight: 500;
  border-bottom: 1px solid $azul;
  text-transform: uppercase;
  .container{
    max-width: 1600px;
  }
  a{
    font-size: 15px;
    color: rgba(0,0,0,0.6);
    &:hover{
      color: $azul;
    }
  }
}
.menu {
	width: 100%;
	@media only screen and (min-width: 1200px) {
		display: flex;
		align-items:center;
		justify-content: space-between;
	}
}
.menu-container {
  margin: 0 auto;
}
.menu a.logo {
    display: inline-block;
    padding: 1.5em 2em;
    width: 19%;
    float: left;
}
.menu .logotipo {
    max-width: 100%;
    width: 215px;
    @media only screen and (max-width: 1400px) {
      width: 180px;
      height: auto;
    }
}
.menu-mobile {
  display: none;
  padding: 20px;
}
.menu-mobile:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0c9";
  font-size: 2rem;
  color: $azul;
  padding: 0;
  top: 50%;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.menu-dropdown-icon {
  position: relative;
  &:before {
    position: absolute;
    right: 20px;
    top: 29px;
    vertical-align: .255em;
    content: "";
    border-top: .25em solid;
    border-right: .25em solid transparent;
    border-bottom: 0;
    border-left: .25em solid transparent;
    color: $azul;
    width: 5px;
    height: 5px;
    cursor: pointer;
    @media only screen and (min-width: 1200px) {
      display: none;
    }
  }
	@include media-breakpoint-down(lg) {
		> a {
			width: calc(100% - 48px) !important;
		}
	}
}
.menu > ul {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: 0;
  /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
  box-sizing: border-box;
  clear: right;
}
.menu > ul:before,
.menu > ul:after {
  content: "";
  display: table;
}
.menu > ul:after {
  clear: both;
}
.menu > ul > li {
  float: left;
  padding: 0;
  margin: 0;
	&.active {
		a {
			color: $azul
		}
	}
}
.menu > ul > li a {
  text-decoration: none;
  padding: 2.5em 0 2.5em 2.5em;
  display: block;
  @media only screen and (max-width: 1550px) {
    padding: 2.5em 1em;
  }
  @media only screen and (max-width: 1400px) {
    padding: 2em 0.8em;
    font-size: 14px;
  }
  @media only screen and (max-width: 1250px) {
    padding: 2em 0.5em;
    font-size: 14px;
  }
}
.menu > ul > li > ul {
  display: none;
  width: 100%;
  background: #f0f0f0;
  padding: 20px;
  position: absolute;
  z-index: 99;
  left: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}
.menu > ul > li > ul:before,
.menu > ul > li > ul:after {
  content: "";
  display: table;
}
.menu > ul > li > ul:after {
  clear: both;
}
.menu > ul > li > ul > li {
  margin: 0;
  padding-bottom: 0;
  list-style: none;
  width: 25%;
  background: none;
  float: left;
}
.menu > ul > li > ul.normal-sub {
  width: 300px;
  left: auto;
  padding: 10px 20px;
}
.menu > ul > li > ul.normal-sub > li {
  width: 100%;
}
.menu > ul > li > ul.normal-sub > li a {
  border: 0;
  padding: 1em 0;
  font-size: 13px;
}
.idioma{
  display: flex;
  align-items: center;
  .nav-link{
    display: flex;
    align-items: center;
  }
  .dropdown-toggle::after{
    color: $white!important;
  }
  .dropdown-menu{
    margin: .3rem 0 0;
    min-width: auto;
    background-color: $azul;
    border-radius: 0;
    a{
      &:hover{
        background-color: transparent;
      }
    }
  }
  img{
    position: relative;
    width: 22px;
    height: 22px;
  }
}
.search {
  background-color: #fff;
  border-bottom: .125rem solid #000;
  opacity: 0;
  position: absolute;
  transition: opacity .3s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 1;
  .form-control {
    border: 0;
    color: $body-color;
    &::placeholder {
      color: rgba(0, 0, 0, .3);
    }
  }
  @include media-breakpoint-up(lg) {
    border-bottom-width: rem(3);
  }
}
.search--open {
  .search {
    opacity: 1;
    visibility: visible;
  }
}
.list-results {
  &__url {
    color: #777;
  }
  &__description {
    font-size: rem(14);
  }
}

@media only screen and (min-width: 1200px) {
  .hidden-computer{
    display: none;
  }
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (max-width: 1199px) {
  .menu-container {
    width: 100%;
  }
  .menu-container .menu{
  display:inline-block;
   }
  .menu-mobile {
    display: block;    
    float: right;    
    padding: 20px 0 0;
  }
  .menu-dropdown-icon:before {
    display: block;
  }
  .menu > ul {
    display: none;
    width:100%;
  }
  .menu > ul > li {
    width: 100%;
    float: none;
    display: block;
  }
  .menu > ul > li a {
    padding: 1.5em 0.5rem;
    width: 100%;
    display: block;
  }
  .menu > ul > li > ul {
    position: relative;    
    padding: 0 40px;
  }
  .menu > ul > li > ul.normal-sub {
    width: 100%;
  }
  .menu > ul > li > ul > li {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  .menu > ul > li > ul > li:first-child {
    margin: 0;
  }
  .menu > ul > li > ul > li > ul {
    position: relative;
  }
  .menu > ul > li > ul > li > ul > li {
    float: none;
  }
  .menu .show-on-mobile {
    display: block;
		overflow-y: auto;
		height: calc(100% - 125px);
		top: 125px;
		position: fixed;
		left: 0;
		background-color: #fff;
		padding: 0 15px;
		width: 100%;
  }
  .hidden-computer{
    a{
      color: $azul; 
    }
  }
}

.menu--opened {
	overflow: hidden
}