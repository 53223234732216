/*
  # Background image with object-fit
  # Opacities
  # Container
  # Generales
  # Encabezados
*/

body {
  &.blocked{
    overflow: hidden;
    position: relative;
    &:before{
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 7;
      background: rgba(0,0,0,.5);
    }
  }
}
// # Background image with object-fit
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; // esto va ligado con el plugin de https://github.com/fregante/object-fit-images
  /* Si se quiere la posición:
  object-position: bottom;
  font-family: 'object-fit: cover; object-position: bottom;';
  */
}
.bg-blue{
  background-color: $azul;
  p, a{
    color: $white!important;
  }
}
.bg-orange{
  background-color: $naranja;
  p, a{
    color: $white!important;
  }
}
.bg-gray{
  background-color: $gray-100;
}
// # Opacities
@each $opacity in $opacities {
  .opacity-#{$opacity} {
   opacity: #{$opacity/100};
  }
}
// # Container
.container-fluid{
  max-width: 1600px;
}
.container{
  max-width: 1400px;
}
.my-5{
  padding: 2rem 0;
}
.masthead{
  margin-top: 3rem!important;
}
// # Generales
a:hover{
  text-decoration: none;
  transition: all 0.4s ease;
}
.zoom{
  padding: 0;
  width: 100%;
  border-radius: 0;
  overflow: hidden;
  cursor: pointer;
  img{
    transform: scale3d(1, 1, 1);
    width: auto;
    height: 100%;
    transition: all 0.5s ease 0s;
    @media only screen and (max-width: 892px) {
      width: 100%;
      height: auto;
    }
  }
  &:hover{
    img{
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
}
.expand{
  img{
    @media (max-width:892px){
      width: 100%;
      height: max-content;
    }
  }
}
// # Encabezados
.h1-cat{
  font-size: 4rem;
  font-weight: 700;
  @media (max-width:991px){
      font-size: 40px;
  }
}
.intro{
  font-size: 18px;
  ul{
    list-style: none;
  }
}


// From https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  //-webkit-hyphens: auto;
  //hyphens: auto;
}

.text-break {
  @include word-wrap();
}


@include media-breakpoint-up(md) {
	.w-sm-auto { width: auto !important }
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fs-20 { font-size: 1.25rem }
