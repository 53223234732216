.mb-5r, .my-5r{
  margin-bottom: 5rem !important;
}
.mt-5r, .my-5r{
  margin-top: 5rem !important;
}
.section-carousel {
  .carousel-cell{
    width: 100%;
    height: 500px;
    counter-increment: carousel-cell;
    .carousel-image{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;
      overflow: hidden;
      img{
        width: auto;
        height: 500px;
      }
    }
  }
}
.list-references{
  .box-img{
    height: 250px;
    overflow: hidden;
  }
  .box-orange{
    height: 100%;
    background-color: $naranja;
    color: $white;
    img{
      width: 100%;
      height: auto;
    }
  }
  h3{
    font-size: 18px;
    color: $white;
    margin: 1rem;
  }
  .col-lg-4 {
    padding: 1rem;
  }
}