.sidebar-content{
    #sidebar{
        display: flex;
        align-items: center;
        background: #036;
        width: 300px;
        height: 100%;
        position: fixed;
        left: -300px;
        top: 0px;
        transition: left 0.3s linear;
        z-index: $zindex-modal;
        &.visible{
            left: 0px;
            transition: left 0.3s linear;
        }
        ul{
            margin:0;
            padding:0 1rem;
            li{
                list-style:none;
                a, span{
                    background: #036;
                    color: $white;
                    font-weight: 600;
                    display: block;
                    width: 280px;
                    padding:10px;
                    text-decoration: none;
                    transition: all 0.5s ease-out;
                }
                a:hover{
                    opacity: 0.4;
                    transition: all 0.5s ease-out;
                }
                ul{
                    margin-left: 1.5rem;
                    width: calc(-1.5rem + 280px);
                    li{
                        font-size: .9em;
                        font-weight: 400;
                      a{
                        max-width: 100%;
                      }
                    }
                }
            }
        }
        &.oversized{
          align-items: normal;
           > ul{
            height: 100%;
            padding-top: 2rem;
            overflow: auto;
          }
        }
    }
}

#sidebar-btn {
	border: 1px solid lighten($azul, 5%);
  border-radius: 50%;
  box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
  display: inline-block;
  vertical-align: middle;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  right: -80px;
  background: $azul;
  span {
    height: 1px;
    background: #fff;
    display: block;
    & + span {
      margin-top: 5px;
    }
    &:nth-child(1){
      width: 44px;
    }
    &:nth-child(2){
      width: 33px;
    }
    &:nth-child(3){
      width: 22px;
    }
  }
  @media (max-width: 1550px) {
    width: 50px;
    height: 50px;
    top: 92%;
    right: -60px;
    span {
    &:nth-child(1){
      width: 34px;
    }
    &:nth-child(2){
      width: 23px;
    }
    &:nth-child(3){
      width: 12px;
    }
  }
  }
}

.sidebar-blue{
  padding: 5rem 0 2rem;
  ul{
    padding: 0 3rem 0 6rem;
    li{
      margin-bottom: 1rem;
      a{
        color: $white;
        font-weight: 600;
        text-transform: uppercase;
        opacity: 0.8;
      }
    }
    .active{
      a{
        opacity: 1;
        color: $white;
        font-family: 'Conv_ClanOT-News'!important;
      }
    }
    @media (max-width: 1450px) {
      padding: 0 3rem;
    }
    @media (min-width: 1200px) {
      &.sticky {
        position: fixed;
        width: 100%;
        top: 50px;
        left: 0;
        z-index: 100;
        padding: 0 3rem 0 5rem;
      }
    }
  }
}