// casos de éxito
.main-casos {
	.masthead {
		margin: 0 auto;
		.h1-cat{
			color: $dark;
		}
		.row{
			align-items: center;
			.box-img{
				display: flex;
    			align-items: center;
    			img{
    				padding: 3rem 3rem 3rem 0;
    			}
				@media only screen and (max-width: 767px) {
					img{
						padding: 0;
					}
				}
			}
		}
	}
	.bg-gray, .bg-orange{
		padding: 4rem 0;
	}
	.categorias{
		max-width: 1600px;
		margin: 0 auto;
		.h2{
		 	font-weight: 600;
		}
		.descripcion{
			@media only screen and (min-width: 992px) {
				column-count: 2;
				p{
					padding: 0 2rem;
				}
			}
		}
		.mb-6{
			margin-bottom: 6rem; 
		}
		.tab_container {
			width: 100%;
			margin: 0 auto;
			padding-top: 70px;
			position: relative;
		}
		input, section {
		  clear: both;
		  padding-top: 10px;
		  display: none;
		}
		label {
		  font-weight: 700;
		  font-size: 18px;
		  display: block;
		  float: left;
		  width: 33%;
		  padding: 1.5em;
		  color: #757575;
		  cursor: pointer;
		  text-decoration: none;
		  text-align: center;
		  background: #f0f0f0;
		}
		#tab1:checked ~ #content1,
		#tab2:checked ~ #content2,
		#tab3:checked ~ #content3,
		#tab4:checked ~ #content4,
		#tab5:checked ~ #content5 {
		  display: block;
		  padding: 20px;
		  background: #fff;
		  color: #999;
		}
		.tab_container .tab-content p,
		.tab_container .tab-content h3 {
		  -webkit-animation: fadeInScale 0.7s ease-in-out;
		  -moz-animation: fadeInScale 0.7s ease-in-out;
		  animation: fadeInScale 0.7s ease-in-out;
		}
		.tab_container .tab-content h3  {
		  text-align: center;
		}
		.tab_container [id^="tab"]:checked + label {
		  color: $naranja;
		  background: #fff;
		  box-shadow: inset 0 3px $naranja;
		}
		.tab_container [id^="tab"]:checked + label .fa {
		  color: #0CE;
		}
		label .fa {
		  font-size: 1.3em;
		  margin: 0 0.4em 0 0;
		}
		/*Media query*/
		@media only screen and (max-width: 900px) {
		  label span {
		    display: none;
		  }
		  .tab_container {
		    width: 98%;
		  }
		}
		/*Content Animation*/
		@keyframes fadeInScale {
		  0% {
		  	transform: scale(0.9);
		  	opacity: 0;
		  }
		  100% {
		  	transform: scale(1);
		  	opacity: 1;
		  }
		}
		.tab-content{
			padding: 4rem 0!important;
		}
		.no_wrap {
		  text-align:center;
		  color: #0ce;
		}
		.link {
		  text-align:center;
		}
		.zoom{
			padding: 1rem;
		}
		.box-img {
			max-height: 280px;
			overflow: hidden; 
		}
		h3{
			font-size: 18px;
			color: $dark;
			padding-bottom: 1rem;
		}
	}
	.ficha-tecnica, .reto, .solucion, .testimonio{
		padding: 4rem 0;
	}
	.testimonio{
		.slide{
    		display: flex;
    		justify-content: center;
    		font-size: 1.7rem;
		}
		blockquote{
		  margin:0;
		  padding:30px;
		  background-color: $white;
		  box-shadow: 0 5px 2px rgba(0,0,0,0.1);
		  position:relative;
		  transition: background-color 0.6s linear;
		}
		li{
			font-size: 20px;
		}
	}
	.destacadas{
		.comunicacion-h2{
			color: $naranja;
			margin-top: 5rem;
		}
	}
}
.main-casos.detalle{
	.masthead {
		max-width: 1400px;
	}
	h1{
		color: $dark;
	}
	.categorias{
		max-width: 1400px;
	}
	.font-gray{
		color: #666;
	}
	.relacionados{
		padding: 3rem 0;
		h2, h3, p{
			color: $white;
		}
		h3{
			font-size: 20px;
		}
		.col-md-3{
			padding: 2rem;
		}
	}
	.interes{
		.home-h2{
			font-size: 45px;
			margin-top: 3rem;
		}
		a{
			color: $dark;
			transition: all 0.4s ease;
			&:hover{
				color: $naranja;
			}
		}
		h3{
			font-size: 20px;
			margin: 2rem;
		}
		.col-md-3{
			padding: 2rem;
		}
		.text-left{
			h3{
				margin-left: 0;
			}
		}
	}
	.interes.bg-gray{
		margin-bottom: 0!important;
	}
	.carousel {
		margin: 2rem 0 4rem;
	}
	.programas {
		.list-references {
			.box-orange {
				span.img {
					display: block;
					padding: 1rem 0;
					background: $white;
					text-align: center;
					img {
						width: auto;
						max-width: 100%;
					}
				}
			}
		}
	}
}