// # Search
.search-content{
  position: relative;
  display: flex;
  align-items: center;
}
.search-button {
  position: relative;
  color: #fff;
  transition: 0.3s;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  padding: 0 0 0 1rem;
}
.search-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(52,52,52,0.7);
    left: 0;
    bottom: 2000px;
    transition: 0.6s;
}
.search-form {
    transition: .6s;
    position: absolute;
    top: 50%;
    background: transparent;
    right: 50%;
    margin-right: -200px;
    margin-top: -65px;
    text-align: center;
}
.search-form .search-bar {
    width: 320px;
    height: 40px;
    padding: 0 4px;
    font-size: 14px;
    border-radius: 2px;
    border: 0;
}
.search-form .searchsubmit {
    width: 100px;
    height: 40px;
    border: 0;
    background: #036;
    color: #fff;
    float: right;
    font-size: 13px;
    text-transform: unset;
    border-radius: 0;
    border-radius: 0;
    cursor: pointer;
}
.close-search {
    position: absolute;
    right: 10px;
    background: none;
    border: 0;
    color: #fff;
    font-size: 20px;
    padding: 10px;
    transform: rotate(0);
    cursor: pointer;
    transition: 0.8s;
}
.close-search:hover {
    transform: rotate(360deg);
    transition: 0.5s;
}
@media only screen and (max-width: 500px) {
    .search-form{
        width: 100%;
        padding: 1rem;
        right: 0;
        margin-right: 0;
        margin-top: 0;
        .search-bar {
            width: 100%;
        }
        .searchsubmit {
            width: 100%;
        }
    }
}