// Personas y talento
.main-talento{
	.h1-cat{
		color: $dark;
		margin-top: 2rem;
	}
	h2.h1-cat{
		font-size: 3rem;
	}
	.categorias{
		margin: 6rem 0 3rem;
		.row:nth-child(2n){
			flex-direction: row-reverse;
		}
		.box-img{
			padding: 0;
		}
		.box-txt{
			padding: 4rem;
		}
		h4{
			color: $dark;
			font-size: 40px;
			position: relative;
			max-width: 500px;
			&:before {
				content: "";
				position: absolute;
			    display: block;
			    margin-top: -1rem;
			    background-color: $orange;
			    width: 2rem;
			    height: .2rem;
			}
		}
		p{
			max-width: 500px;
		}
		.btn-download{
			font-size: 20px;
			padding: 1rem;
    		font-size: 15px;
    		width: 200px;
    		span {
			    padding-right: .8rem;
			}
		}
		.mb-5{
			margin-bottom: 5rem;
		}
	}
	.convocatorias{
		.home-h2{
			color: $dark;
		}
		.box {
		    width: 100%;
		    background-color: #fff;
		    text-align: left;
		    position: relative;
		    height: 100%;
		    box-shadow: 0px 2px 6px 0px rgba(204, 209, 217, .5);
		    border: 1px solid #E6E9ED;
		}
		.box-txt {
		    padding: 7%;
		    min-height: 140px;
		}
		.font-bold{
			font-weight: 600;
			color: $dark;
		}
		.show-more{
			font-weight: 600;
			color: $naranja;
			span{
				padding-right: 0.5rem;
			}
		}
	}
}
.compromiso{
	.descripcion{
		@media only screen and (min-width: 992px) {
			column-count: 2;
			p{
				padding: 0 2rem;
			}
		}
	}
}
.trabaja{
	.masthead{
		margin-bottom: 6rem;
	}
	.section-intro{
		padding-top: 2rem;
	}
	@media only screen and (min-width: 992px) {
		.col-lg-5{
			padding-right: 6rem;
		}
	}
	.poppins{
		font-size: 16px;
	}
	.box{
		.poppins{
			font-size: 25px;
		}
	}
}
.formacion{
	.form-control{
		padding: 1rem;
	}
	.btn-mtorres {
	    font-size: 14px;
	    padding: .8rem 3rem;
	    text-transform: uppercase;
	}
	@media only screen and (min-width: 992px) {
		.col-lg-5{
			padding-right: 4rem;
			h3{
				margin-bottom: 2rem;
			}
		}
	}
}